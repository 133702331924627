import React from 'react';
import './StoryIntro.css';
import introImage from '../assets/28EFE606-9184-4D0A-A183-9E746437AE80.jpeg';
import { Link } from 'react-router-dom';

const StoryIntro = () => {
  return (
    <div className="story-intro">
      <h2>Tales of Zypherion: Rise and Rebellion of the Wrecked Croakers</h2>
      <p>
        In the cosmic expanse of 'Tales of Zypherion,' once-harmonious Wrecked Croakers face a brutal rebellion. Trust shattered, they rise, fueled by fury, to reclaim what was unjustly taken. As the universe watches, the Wrecked Croakers embark on a relentless quest for revenge, seeking to restore peace to their cosmic home.
      </p>
      <div
        className="intro-image"
        style={{
          backgroundImage: `url(${introImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <h3 style={{marginTop:'30px', color:'black'}}>Want to find out more? It’s worth it!<br></br>
Click on the link below and connect your wallet to get access to go on the other side.
</h3>
       <Link to="/stories">
        <button className='view-story2'>Take me further</button>
      </Link>
    </div>
  );
};

export default StoryIntro;