import React from 'react';
import '../App.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-left">
        <p>&copy; 2023 Wrecked Croakers. All rights reserved.</p>
      </div>
      <div className="footer-right">
        <a href="/Terms of Service- Wrecked Croakes.pdf" target="_blank" rel="noopener noreferrer">Terms of Service</a>
        <a href="/Privacy Policy- Wrecked Croakers.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        <a href="/Risk Disclaimer and Disclosure- Wrecked Croakers.pdf" target="_blank" rel="noopener noreferrer">Risk Disclosure</a>
      </div>
    </footer>
  );  
}

export default Footer;
