import React, { useState } from 'react';
import './MintTransferPage.css';
import { ethers } from 'ethers';
import { useSigner } from '@thirdweb-dev/react';
import ABI from '../ABI.json'; // Adjust the path as necessary

const contractAddress = "0xe76465007bacb8be7ef8d2ae7f6ee46e2ffee4e8";

const MintTransferPage = () => {
  const [recipientAddress, setRecipientAddress] = useState('');
  const [mintAmount, setMintAmount] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [lastMintedTokenId, setLastMintedTokenId] = useState(null); // For tracking the last minted token ID
  
  // Assumes useSigner hook provides the current connected wallet's signer
  const signer = useSigner();

  const handleMintAndTransfer = async () => {
    if (!signer) {
      alert("Please connect your wallet.");
      return;
    }
    if (!recipientAddress || mintAmount < 1) {
      alert("Please enter a valid recipient address and mint amount.");
      return;
    }

    setIsProcessing(true);
    const contract = new ethers.Contract(contractAddress, ABI, signer);

    try {
      // Mint NFTs to the current user's address
      const mintTx = await contract.presaleMint(mintAmount, { value: ethers.utils.parseEther("0") }); // Assuming minting is free or adjust the value accordingly
      await mintTx.wait();

      // Retrieve the current token count (assuming this is the last minted token ID)
      const currentTokenCount = await contract.totalSupply();
      let lastMintedTokenId = currentTokenCount.sub(mintAmount);

      // Transfer NFTs to the recipient address
      for (let i = 0; i < mintAmount; i++) {
        const transferTx = await contract.transferFrom(await signer.getAddress(), recipientAddress, lastMintedTokenId.add(i));
        await transferTx.wait();
      }

      console.log("NFTs minted and transferred successfully!");
      setLastMintedTokenId(lastMintedTokenId.add(mintAmount - 1)); // Update the last minted token ID
    } catch (error) {
      console.error("Operation failed:", error);
      alert("Operation failed. See console for details.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="mint-transfer-page">
      <h2>Airdrop NFTs</h2>
      <div className="mint-section">
        
        <div className="mint-input-container">
          <input
            type="number"
            min="1"
            value={mintAmount}
            placeholder="Number of NFTs"
            onChange={(e) => setMintAmount(parseInt(e.target.value))}
          />
          <input
            type="text"
            placeholder="Recipient Address"
            value={recipientAddress}
            onChange={(e) => setRecipientAddress(e.target.value)}
          />
          <button onClick={handleMintAndTransfer} disabled={isProcessing}>
            {isProcessing ? 'Processing...' : 'Airdrop'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MintTransferPage;
