// ConnectionStatus.js
import React from 'react';
import { useConnectionStatus } from '@thirdweb-dev/react';

const ConnectionStatus = ({ children }) => {
  const connectionStatus = useConnectionStatus();

  return children({ connectionStatus });
};

export default ConnectionStatus;