import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [expandedItems, setExpandedItems] = useState([]);

  const toggleItem = (index) => {
    setExpandedItems(expandedItems.includes(index) 
      ? expandedItems.filter((i) => i !== index) 
      : [...expandedItems, index]);
  };

  // Define the list of FAQ questions and answers
  const faqList = [
    {
      question: "How many Wrecked Croakers NFTs are available in total?",
      answer: "Wrecked Croakers offers a total of 10,013 non-fungible tokens (NFTs) for ownership and participation."
    },
    {
      question: "What is the royalty percentage for resales of Wrecked Croakers NFTs?",
      answer: "For every resale of a Wrecked Croakers NFT, the website/owner retains a royalty of 5% of the sale price."
    },
    {
      question: "What incentives are provided for owning Wrecked Croakers NFTs?",
      answer: "Owners of Wrecked Croakers NFTs gain exclusive access to additional privileges such as receiving access to a novel. Chapters are to be released soon, and ownership of the associated NFT is required to access the content.      ."
    },
    {
      question: "Which blockchain is utilized for Wrecked Croakers NFTs?",
      answer: "Wrecked Croakers NFTs are built on the Ethereum blockchain, providing a secure and transparent platform for ownership and transactions."
    },
    {
      question: "How can I get access to the Chapters of \"Tales of Zypherion: Rise and Rebellion of the Wrecked Croakers\"?",
      answer: "To get access to the chapters, your wallet should be connected to our website and must own a token to get access to the chapters. Chapters will be releasing soon.      "
    },
    {
      question: "How can I get access to the short summary of \"Tales of Zypherion: Rise and Rebellion of the Wrecked Croakers\"?",
      answer: "You can simply connect your wallet to get access to the summary of the Novel, you don’t need to own a Token. You can go through the summary and decide if you would like to continue on this journey or not."
    },
    {
      question: "Would there be any presale to this project and what would be the minting price?",
      answer: "Yes, there will be a limited number of presales in this collection. Follow us on twitter to get the latest updates. You only pay the gas fee to mint during presale."
    },
    {
      question: "Would there be any projects or products related to Wrecked Croakers in the future and will holders get any benefits in them?",
      answer: "Yes, there will be more stuff coming related to Wrecked Croakers and the holders will get exclusive access and discounts on them. As of now, we cannot reveal what the projects will consist of and when will they release. But we can assure you there will be a privilege given to the holders. Follow us on twitter to get the latest updates."
    }
  ];

  return (
    <div className="faq">
      <h2>FAQ</h2>
      <div className="faq-items">
        {faqList.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-item-header" onClick={() => toggleItem(index)}>
              <h3>{faq.question}</h3>
              <span className={`arrow ${expandedItems.includes(index) ? 'open' : ''}`}>&#9660;</span>
            </div>
            <div className={`faq-item-content ${expandedItems.includes(index) ? 'open' : ''}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
