import React, { useState } from 'react';
import { useSigner } from '@thirdweb-dev/react';
import { ethers } from 'ethers';
import Modal from './Modal'; // Assuming you have the Modal component from the previous instruction
import ABI from '../ABI.json'; // Adjust the path as necessary
import './MintModal.css'; // Create and style your modal

const contractAddress = "0xe76465007bacb8be7ef8d2ae7f6ee46e2ffee4e8";

const MintModal = ({ isOpen, onClose }) => {
  const signer = useSigner();
  const [mintAmount, setMintAmount] = useState(1);
  const [isMinting, setIsMinting] = useState(false);
  const [mintSuccess, setMintSuccess] = useState(false);

  const handleMint = async () => {
    if (!signer) {
      alert("Please connect your wallet.");
      return;
    }

    setIsMinting(true);
    const contract = new ethers.Contract(contractAddress, ABI, signer);

    try {
      const value = ethers.utils.parseEther((0 * mintAmount).toString()); // Example: 0.05 ETH per NFT
      const tx = await contract.presaleMint(mintAmount, { value });
      await tx.wait();
      setMintSuccess(true);
      console.log("NFT minted successfully!");
    } catch (error) {
      console.error("Minting failed:", error);
      alert("Minting failed. See console for details.");
    } finally {
      setIsMinting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="mint-container">
        <h2>Mint Your NFT</h2>
        {mintSuccess ? (
          <p>NFT minted successfully!</p>
        ) : (
          <><div className='mint-section'>
            <div className="mint-controls">
              <button onClick={() => setMintAmount(prev => Math.max(1, prev - 1))}>-</button>
              <span>{mintAmount}</span>
              <button onClick={() => setMintAmount(prev => Math.min(2, prev + 1))}>+</button>
            </div>
            <button className='mint-button' onClick={handleMint} disabled={isMinting}>
              {isMinting ? 'Minting...' : `Mint ${mintAmount} NFT${mintAmount > 1 ? 's' : ''}`}
            </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default MintModal;
