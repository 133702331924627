// Hero.js
import React, { useState } from 'react';
import heroImage from './4.png'
import MintModal from './MintModal';

function Hero() {
  const [isMintModalOpen, setIsMintModalOpen] = useState(false);
  
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Dive into Digital Rebellion: Wrecked Croakers, Where Chaos Meets Creativity!</h1>
       
        <button className='cta-button' onClick={() => setIsMintModalOpen(true)}>Explore Collection</button>
      <MintModal isOpen={isMintModalOpen} onClose={() => setIsMintModalOpen(false)} />
  
      </div>
      <div className="hero-image">
        <img src={heroImage} alt="Wrecked Croakers" />
      </div>
    </section>
  );
}

export default Hero;