import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import './StoryChapters.css';
import StorySummary from './StorySummary';
import ABI from '../ABI.json';
import Modal from './Modal';

const nftContractAddress = "0xe76465007bacb8be7ef8d2ae7f6ee46e2ffee4e8";
const nftABI = ABI; // Your NFT contract's ABI

// Array holding the content for each chapter
const chaptersInfo = [
  {
    content: "Zypherion, a tranquil haven of thirteen harmonious colonies, basked in an era of unprecedented peace and contentment...",
    imageUrl: "../assets/758D6058-717C-4148-A59C-36830DC66FC7.jpeg", // Adjust the path as needed
  },
  {
    content: "Chapter 2 content goes here...",
    imageUrl: "/path/to/your/chapter2-image.jpg",
  },
  // Repeat for all chapters
];


const StoryChapters = () => {
  const [chapterContent, setChapterContent] = useState('');
  const [ownsNFT, setOwnsNFT] = useState(false);
  const [currentChapter, setCurrentChapter] = useState(1);
  const [showSummary, setShowSummary] = useState(true);
  const [userAddress, setUserAddress] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewStoryClick = () => {
    
      setIsModalOpen(true); // Open the modal instead of showing an alert
    
  };

  useEffect(() => {
    const connectWalletAndGetNFTStatus = async () => {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const accounts = await provider.send("eth_requestAccounts", []);
          const userAddress = accounts[0];
          setUserAddress(userAddress);

          const contract = new ethers.Contract(nftContractAddress, nftABI, provider);
          const balance = await contract.balanceOf(userAddress);
          setOwnsNFT(balance.gt(0));
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    connectWalletAndGetNFTStatus();
  }, []);

  const handleChapterSelection = (chapterNumber) => {
    if (ownsNFT || chapterNumber === 1) {
      setCurrentChapter(chapterNumber);
    } else {
      setIsModalOpen(true);
    }
  };

  const fetchChapterContent = async (chapterNumber) => {
    // Fetch or define the content for the chapter
    return `This is the content for Chapter ${chapterNumber}.`;
  };

  return (
    <div className="story-chapters">
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h3 style={{padding:'300px', paddingTop:'0px', paddingBottom:'50px', textAlign:'center'}}>Coming Soon!
It's worth to wait.</h3>
      </Modal>
      <h2>Tales of Zypherion</h2>
      <h3>What is it about</h3>
<div className="chapters-container">
  
  <div className="chapter-content">
  {showSummary ? (
    <div>
      <StorySummary />
      <h3 style={{padding:'300px', paddingTop:'0px', paddingBottom:'50px', textAlign:'center'}}>It is not the end!
Curious to discover the origin and conclusion of this tale? Click the link below to confirm your role in the revolution. Let us take you through every twist and turn of this epic journey.</h3>
      <button className="view-story" onClick={handleViewStoryClick}>Take me to the Chapters</button>
    </div>
  ) : (
    <div>
      <p>{chapterContent}</p>
    </div>
  )}
</div>
</div>

    </div>
  );
};

export default StoryChapters;
