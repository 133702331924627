import React from 'react';
import Slider from './Slider';
import Slider2 from './Slider2'

function About() {
  return (
    <div>
    <section id="about" className="about">
      <h2>About Wrecked Croakers</h2>
      <p>Introducing "Wrecked Croakers" – an electrifying handmade NFT collection that pushes the boundaries of digital art and storytelling! Immerse yourself in a world where 10,013 unique tokens come to life, each telling a distinctive tale of chaos, creativity, and charisma.</p>
        </section>
        <Slider></Slider>
        <Slider2></Slider2>
        <section id="about" className="about">
          <h2>What is Zypherion?</h2>
        <p>Zypherion, a distant realm of imagination, once teemed with beauty and abundance. It birthed the Wrecked Croakers, where chaos met with creativity, sculpting the essence of its being and laying the foundation for the reign of the 13 kings.</p>
    </section>
    </div>
  );  
}

export default About;