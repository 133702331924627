import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ThirdwebProvider, ConnectWallet, metamaskWallet, coinbaseWallet, walletConnect, trustWallet } from '@thirdweb-dev/react';
import './App.css';
import Hero from './components/Hero';
import About from './components/About';
import Footer from './components/Footer';
import StoryIntro from './components/StoryIntro';
import Goal from './components/Goal';
import FAQ from './components/FAQ';
import logo from './logo.png';
import StoryChapters from './components/StoryChapters2';
import ConnectionStatus from './components/ConnectionStatus';
import MintTransferPage from './components/MintTransferPage';
import { FaTwitter } from 'react-icons/fa';

function App() {
  return (
    <ThirdwebProvider activeChain="ethereum" clientId="9d0cd6937119b9ffcda97be6bd8a180d" supportedWallets={[metamaskWallet(), coinbaseWallet(), walletConnect(), trustWallet()]}>
      <Router>
        <div className="app">
          <header className="header">
            <a href="/">
              <img src={logo} alt="Logo" className="logo" />
            </a>
            <nav>
              <ul>
                <li>
                  <a href="/#about">About</a>
                </li>
                <li>
                  {/* Link to Twitter using an anchor tag and the Twitter icon */}
                  <a href="https://twitter.com/wreckedcroakers" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                    <FaTwitter />
                  </a>
                </li>
                <ConnectWallet style={{ backgroundColor: '#b8dd97', color: '#000000', fontWeight: '700' }} />
              </ul>
            </nav>
          </header>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <ConnectionStatus>
                  {({ connectionStatus }) => (
                    <>
                      <Hero />
                      <About />
                      <StoryIntro />
                      <FAQ />
                    </>
                  )}
                </ConnectionStatus>
              }
            />
            <Route
              path="/stories"
              element={
                <ConnectionStatus>
                  {({ connectionStatus }) =>
                    connectionStatus === 'connected' ? <StoryChapters /> : <h2 style={{ display: 'flex', justifyContent: 'center' }}>Connect your wallet to get access to go on the other side.</h2>
                  }
                </ConnectionStatus>
              }
            />
            <Route
              path="/airdrop"
              element={
                <ConnectionStatus>
                  {({ connectionStatus }) =>
                    connectionStatus === 'connected' ? <MintTransferPage /> : <h2 style={{ display: 'flex', justifyContent: 'center' }}>Connect your wallet to mint and transfer NFTs.</h2>
                  }
                </ConnectionStatus>
              }
            />
          </Routes>
          <Footer />
        </div>
      </Router>
    </ThirdwebProvider>
  );
}

export default App;