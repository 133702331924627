import React, { useState } from 'react';
import './StorySummary.css';
import summaryImage from '../assets/758D6058-717C-4148-A59C-36830DC66FC7.jpeg';

const StorySummary = () => {
  const [showSummary, setShowSummary] = useState(true);

  const handleConnectWallet = () => {
    // Implement your wallet connection logic here
    setShowSummary(true);
  };

  return (
    <div className="story-summary">
      {showSummary ? (
        <div className="summary-container">
          <div className="summary-content">
            <p>
              Zypherion, a tranquil haven of thirteen harmonious colonies, basked in an era of unprecedented peace and contentment. The Croaker residents joyfully coexisted, cherishing the serenity of their shared existence. Yet, this utopian harmony was abruptly disrupted when ambitions for power emerged among the Croakers. The once-idyllic planet, free from conflicts and turmoil, witnessed the ascent of a charismatic leader who birthed an army from the aspirations of thirteen ambitious figures.
            </p>
            <p>
              As these leaders united, Zypherion's peaceful landscape transformed into a battleground of broken promises, betrayal, and brutal oppression. The blissful unity among the residents disintegrated, replaced by a stark divide between the powerful Kings and the oppressed Wrecked Croakers. The unfolding chapters of Zypherion's tale encapsulate a journey from tranquility to turmoil, with the resilient Wrecked Croakers seeking redemption and justice amidst the echoes of oppression that shattered their serene existence.
            </p>
          </div>
          <div
            className="summary-image"
            style={{
              backgroundImage: `url(${summaryImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default StorySummary;