import React from 'react';
import './Slider2.css';

// Import images
import image1 from '../41.png';
import image2 from '../53.png';
import image3 from '../58.png';
import image4 from '../61.png';
import image5 from '../66.png';

const ImageSlider = () => {
  const images = [image1, image2, image3, image4, image5];

  return (
    <div className="image-slider">
      <div className="slide slide-right">
        {[...images, ...images].map((image, index) => (
          <img key={index} src={image} alt={`Slide ${index % images.length + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;